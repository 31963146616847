const content = () => {
  const contentInit = () => {
    const logo = document.querySelector('.c-logo');
    const textContent = document.querySelector('.text-content');
    if (logo && textContent) {
      if (window.matchMedia('screen and (min-width: 992px)').matches) {
        textContent.style.marginLeft = `${logo.clientWidth + 55}px`;
      } else {
        textContent.style.marginLeft = 0;
      }
    }
  };
  contentInit();
  window.addEventListener('resize', contentInit);
};
export default content;
