const addParameterToURL = (paramName, paramValue) => {
  // Get the current URL
  const currentURL = new URL(window.location.href);

  // Add a new parameter
  currentURL.searchParams.set(paramName, paramValue);

  // Update the browser's address bar
  window.history.replaceState({}, document.title, currentURL.href);

  const wpmlLs = document.querySelector('.wpml-ls');

  if (wpmlLs) {
    const getLinks = wpmlLs.querySelectorAll('a');

    if (getLinks.length > 0) {
      getLinks.forEach((single) => {
        const href = new URL(single.getAttribute('href'));

        href.searchParams.set(paramName, paramValue);

        single.setAttribute('href', href);
      });
    }
  }

  const institutionsLinks = document.querySelectorAll(
    '.c-institutions-search__cat-link',
  );

  if (institutionsLinks.length > 0) {
    institutionsLinks.forEach((single) => {
      const href = new URL(single.getAttribute('href'));

      href.searchParams.set(paramName, paramValue);

      single.setAttribute('href', href);
    });
  }
};

export default addParameterToURL;
