import * as L from 'leaflet';

const searchLocalization = (localization, type, map) => {
  let featureType = '';

  if (type === 'miejscowosci' || type === 'ortschaften') {
    featureType = 'city';
  } else if (
    type === 'powiaty' ||
    type === 'wojewodztwa-kraje-zwiazkowe' ||
    type === 'bundeslaender-woiwodschaften' ||
    type === 'landkreise'
  ) {
    featureType = 'county';
  }
  console.log(
    'POST: ',
    `https://nominatim.openstreetmap.org/search?q=${localization}&featureType=${featureType}&polygon_geojson=1&format=geojson`,
  );
  fetch(
    `https://nominatim.openstreetmap.org/search?q=${localization}&featureType=${featureType}&polygon_geojson=1&format=geojson`,
  )
    .then((res) => res.json())
    .then((data) => {
      if (data) {
        console.log('data: ', data.features);
        data.features.forEach((feature) => {
          if (
            featureType === 'city' &&
            (feature.properties.addresstype === 'town' ||
              feature.properties.addresstype === 'city')
          ) {
            L.geoJSON(feature, {
              stroke: false,
              fillColor: '#29449c',
              fillOpacity: 0.3,
            }).addTo(map);
          }
          if (
            featureType === 'county' ||
            feature.properties.addresstype === 'county'
          ) {
            L.geoJSON(feature, {
              stroke: false,
              fillColor: '#29449c',
              fillOpacity: 0.3,
            }).addTo(map);
          }
        });
      }
    });
};

export default searchLocalization;
