const scrollRemember = () => {
  if (typeof Storage !== 'undefined') {
    // zapisanie obecnego języka
    const htmlLang = document.querySelector('html').getAttribute('lang');
    const savedLang = localStorage.getItem('lang');
    if (htmlLang) {
      if (savedLang !== htmlLang) {
        localStorage.setItem('lang', htmlLang);
      }
    }

    // See if there is a scroll pos and go there.
    const lastYPos = +localStorage.getItem('scrollYPos');
    if (lastYPos && savedLang !== htmlLang) {
      window.scrollTo(0, lastYPos);
    }
  }

  const scrollSave = () => {
    if (typeof Storage !== 'undefined') {
      // zapisanie pozycji
      localStorage.setItem('scrollYPos', window.scrollY);
    }
  };
  window.addEventListener('scroll', scrollSave);
};
export default scrollRemember;
