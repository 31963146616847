import Swiper, { Pagination } from 'swiper';

const hero = () => {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.s-hero__slider', {
    modules: [Pagination],
    slidesPerView: 1.2,
    spaceBetween: 15,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
};

export default hero;
