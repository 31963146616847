/*
  Project: PNWM
  Author: Daniel Kozina
 */

import toggle from './modules/toggle';
import hero from './modules/hero';
import language from './modules/language-picker';
import singleProject from './modules/single-project';
import accordionList from './modules/accordion-list';
import searchBar from './modules/searchbar';
import projectsSearch from './modules/projects-search';
import institutionsSearch from './modules/institutions-search';
import map from './modules/map';
import jugendProjekte from './modules/jugendprojekte';
import institutions from './modules/institutions';
import scrollToSmoothly from './modules/scroll-to-smoothly';
import content from './modules/content';
import scrollRemember from './modules/scroll-remember';
// import setCookie from './modules/set-cookie';

document.addEventListener('DOMContentLoaded', () => {
  toggle();
  hero();
  language();
  singleProject();
  accordionList();
  searchBar();
  projectsSearch();
  institutionsSearch();
  jugendProjekte();
  institutions();

  window.addEventListener(
    'resize',
    () => {
      hero();
      console.log('test');
    },
    true,
  );

  // eslint-disable-next-line no-undef
  if (document.body.classList.contains('pnwm-position-save')) {
    scrollRemember();
  }

  if (document.querySelector('.s-map')) {
    map();
  }
  //  else {
  //   setCookie('user-relation', ' ');
  //   setCookie('user-position', ' ');
  // }

  setTimeout(() => {
    if (window.location.hash) {
      let offset = 0;
      const header = document.querySelector('.c-header');
      const scrollSearchBar = document.querySelector('.scroll-search-bar');

      const scrollToElement = document.querySelector(window.location.hash);
      if (scrollToElement) {
        if (scrollSearchBar) {
          offset += scrollSearchBar.clientHeight;
        }
        if (header) {
          offset += header.clientHeight;
        }
        scrollToSmoothly(scrollToElement.offsetTop - offset, 1000);
      }
    }
  }, 500);

  // const scrollTarget = document.querySelector('#search-title');
  // if (scrollTarget != null) {
  //   scrollToSmoothly(scrollTarget.offsetTop - 114, 1000);
  // }
  const path = window.location.pathname.split('/');
  if (
    path[path.length - 3] === 'cat-project' ||
    path[path.length - 3] === 'cat-institution'
  ) {
    const slug = document.querySelector(
      `[data-slug*=${path[path.length - 2]}]`,
    );
    const slugtext = slug.getAttribute('data-slug');
    if (window.location.href.indexOf(`/${slugtext}/` !== -1)) {
      slug.classList.add('is-active');
    }
  }

  const singleProjectCategory = document.querySelector(
    '.c-single-project__category-link',
  );
  if (singleProjectCategory != null) {
    const slugtext = singleProjectCategory.getAttribute('data-slug');
    const slug = document.querySelector(`[data-slug*=${slugtext}]`);
    slug.classList.add('is-active');
  }

  const searchicon = document.querySelector('.c-header__search');
  if (searchicon !== null) {
    searchicon.addEventListener('click', (e) => {
      e.preventDefault();
      const mainSearch = document.querySelector('.c-main-search');
      mainSearch.classList.toggle('c-main-search--is-show');
    });
  }

  document.querySelectorAll('a[href^="#"]').forEach((trigger) => {
    const triggerP = trigger;
    triggerP.onclick = function fun(e) {
      let offset = 0;
      const hash = e.currentTarget.getAttribute('href').split('#');
      if (hash[1]) {
        const scrollTarge = document.querySelector(`#${hash[1]}`);
        if (scrollTarge) {
          const scrollSearchBar = document.querySelector('.scroll-search-bar');
          const header = document.querySelector('.c-header');
          if (scrollSearchBar) {
            offset += scrollSearchBar.clientHeight;
          }
          if (header) {
            offset += header.clientHeight;
          }
          scrollToSmoothly(scrollTarge.offsetTop - offset, 1000);
        }
      }
    };
  });

  const insitutionsForm = document.querySelector(
    '.c-institutions-search__form',
  );

  const jugendForm = document.querySelector('.c-projects-search__form');

  const cSearchBarInput = document.querySelector('.c-search-bar__input');

  if (insitutionsForm || jugendForm || cSearchBarInput) {
    const institutionFormInit = () => {
      const logo = document.querySelector('.c-logo');

      if (window.matchMedia('screen and (min-width: 992px)').matches) {
        if (logo && insitutionsForm) {
          insitutionsForm.style.width = `${logo.clientWidth}px`;
        }
        if (logo && jugendForm) {
          jugendForm.style.width = `${logo.clientWidth}px`;
        }
        if (logo && cSearchBarInput) {
          cSearchBarInput.style.width = `${logo.clientWidth}px`;
        }
      }
    };
    institutionFormInit();
    window.addEventListener('resize', institutionFormInit);
  }

  content();
});
