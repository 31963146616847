const languagePicker = () => {
  const arrow = document.querySelector('.c-language-picker__arrow');
  const cLanguagePickerLinkActive = document.querySelector(
    '.c-language-picker__link--active',
  );
  if (cLanguagePickerLinkActive) {
    cLanguagePickerLinkActive.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget
        .closest('.c-language-picker')
        .classList.toggle('c-language-picker--active');
    });
  }

  if (arrow) {
    arrow.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget
        .closest('.c-language-picker')
        .classList.toggle('c-language-picker--active');
    });
  }
};

export default languagePicker;
