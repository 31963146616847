import addParameterToURL from './add-parameter-to-url';
import getCookie from './get-cookie';
import getParameterFromURL from './get-parameter-from-url';
// import setCookie from './set-cookie';

const resetTabs = () => {
  const tabs = document.querySelectorAll('.accordion__tab');
  if (tabs) {
    tabs.forEach((accordion) => {
      accordion.classList.remove('accordion__tab--is-open');
      accordion
        .closest('.accordion__list-item')
        .querySelector('.accordion__panel')
        .classList.remove('accordion__panel--is-open');
    });
  }
};

const accordionList = () => {
  const tabs = document.querySelectorAll('.accordion__tab');

  if (tabs) {
    tabs.forEach((accordion) => {
      accordion.addEventListener('click', (e) => {
        if (e.currentTarget.classList.contains('accordion__tab--is-open')) {
          e.currentTarget.classList.remove('accordion__tab--is-open');
          e.currentTarget
            .closest('.accordion__list-item')
            .querySelector('.accordion__panel')
            .classList.remove('accordion__panel--is-open');
        } else {
          resetTabs();
          e.currentTarget.classList.add('accordion__tab--is-open');
          e.currentTarget
            .closest('.accordion__list-item')
            .querySelector('.accordion__panel')
            .classList.add('accordion__panel--is-open');
        }

        if (e.currentTarget.getAttribute('data-translation')) {
          addParameterToURL(
            'tab-data-translation',
            e.currentTarget.getAttribute('data-translation'),
          );
          // setCookie(
          //   'tab-data-translation',
          //   e.currentTarget.getAttribute('data-translation'),
          //   7,
          // );
        }

        if (e.currentTarget.getAttribute('data-target')) {
          addParameterToURL(
            'tab-data-target',
            e.currentTarget.getAttribute('data-target'),
          );
          // setCookie(
          //   'tab-data-target',
          //   e.currentTarget.getAttribute('data-target'),
          //   7,
          // );
        }
      });
    });
    if (getParameterFromURL('tab-data-translation')) {
      const tab = document.querySelector(
        `[data-target="${getParameterFromURL('tab-data-translation')}"]`,
      );
      if (tab) {
        resetTabs();
        tab.classList.add('accordion__tab--is-open');
        tab.parentNode
          .querySelector('.accordion__panel')
          .classList.add('accordion__panel--is-open');
      }
    }

    if (getParameterFromURL('tab-data-translation')) {
      const tab = document.querySelector(
        `[data-translation="${getParameterFromURL('tab-data-translation')}"]`,
      );
      if (tab) {
        resetTabs();
        tab.classList.add('accordion__tab--is-open');
        tab.parentNode
          .querySelector('.accordion__panel')
          .classList.add('accordion__panel--is-open');
      }
    }

    if (getParameterFromURL('tab-data-target')) {
      const tab = document.querySelector(
        `[data-target="${getParameterFromURL('tab-data-target')}"]`,
      );
      if (tab) {
        resetTabs();
        tab.classList.add('accordion__tab--is-open');
        tab.parentNode
          .querySelector('.accordion__panel')
          .classList.add('accordion__panel--is-open');
      }
    }

    if (getParameterFromURL('tab-data-target')) {
      const tab = document.querySelector(
        `[data-translation="${getParameterFromURL('tab-data-target')}"]`,
      );

      if (tab) {
        resetTabs();
        tab.classList.add('accordion__tab--is-open');
        tab.parentNode
          .querySelector('.accordion__panel')
          .classList.add('accordion__panel--is-open');
      }
    }
  }
};
export default accordionList;
