const getParameterFromURL = (name) => {
  // Get the current URL
  const url = new URL(window.location.href);

  // Get the value of the parameter
  const paramValue = url.searchParams.get(name);

  return paramValue;
};

export default getParameterFromURL;
