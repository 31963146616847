import addParameterToURL from './add-parameter-to-url';
import getParameterFromURL from './get-parameter-from-url';
import scrollToSmoothly from './scroll-to-smoothly';

const resetProjects = () => {
  const projects = document.querySelectorAll(
    '.c-institutions-search__cat-link',
  );
  if (projects) {
    projects.forEach((element) => {
      element.classList.remove('c-institutions-search__cat-link--active');
    });
  }
};

const institutions = () => {
  let offset = 0;
  const searchBar = document.querySelector('.c-institutions-search');
  const header = document.querySelector('.c-header');
  if (header) {
    offset += header.clientHeight;
  }
  if (searchBar) {
    offset += searchBar.clientHeight;
  }

  const institutionsMaps = document.querySelectorAll(
    '.s-institutions__panel-map',
  );

  if (institutionsMaps) {
    institutionsMaps.forEach((element) => {
      const lat = element.getAttribute('data-lat');
      const lng = element.getAttribute('data-lng');
      // eslint-disable-next-line no-undef
      const position = new google.maps.LatLng(lat, lng);
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(element, {
        // eslint-disable-next-line no-undef
        center: new google.maps.LatLng(lat, lng),
        zoom: 15,
        // eslint-disable-next-line no-undef
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
      const icon = {
        // eslint-disable-next-line no-undef
        url: localizeVars.settings.markerSecondary,
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(30, 40), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(15, 45), // anchor
      };
      // eslint-disable-next-line no-undef, no-unused-vars
      const marker = new google.maps.Marker({
        position,
        map,
        // eslint-disable-next-line no-undef
        icon,
      });
    });
  }
  const projects = document.querySelectorAll(
    '.c-institutions-search__cat-link',
  );
  if (projects.length > 0) {
    projects.forEach((element) => {
      element.addEventListener('click', (e) => {
        resetProjects();
        e.currentTarget.classList.add(
          'c-institutions-search__cat-link--active',
        );

        scrollToSmoothly(
          document.getElementById(
            e.currentTarget.parentNode.getAttribute('data-slug'),
          ).offsetTop - 190,
          1000,
        );

        addParameterToURL(
          'institutions-data-translation',
          e.currentTarget.getAttribute('data-translation'),
        );

        addParameterToURL(
          'institutions-data-target',
          e.currentTarget.getAttribute('data-target'),
        );
      });
    });
  }

  if (
    getParameterFromURL('institutions-data-translation') &&
    !getParameterFromURL('tab-data-translation')
  ) {
    const searchBtn = document.querySelector(
      `[data-target="${getParameterFromURL('institutions-data-translation')}"]`,
    );
    if (searchBtn) {
      searchBtn.classList.add('c-institutions-search__cat-link--active');
      const getHtref = searchBtn.getAttribute('href').split('#')[1];
      if (getHtref) {
        const searchScrollTarget = document.getElementById(getHtref);
        if (searchScrollTarget) {
          setTimeout(() => {
            scrollToSmoothly(searchScrollTarget.offsetTop - offset, 1000);
          }, 1000);
        }
      }
    }
  } else if (getParameterFromURL('tab-data-translation')) {
    const getDataTranslationTab = document.querySelector(
      `[data-target="${getParameterFromURL('tab-data-translation')}"]`,
    );

    if (getDataTranslationTab) {
      setTimeout(() => {
        scrollToSmoothly(getDataTranslationTab.offsetTop - offset, 1000);
      }, 1000);
    }
  }

  if (getParameterFromURL('institutions-data-target')) {
    const searchBtn = document.querySelector(
      `[data-target="${getParameterFromURL('institutions-data-target')}"]`,
    );
    if (searchBtn) {
      searchBtn.classList.add('c-institutions-search__cat-link--active');
    }
  }
};

export default institutions;
