const toggle = () => {
  const cToggle = document.querySelector('.c-toggle');
  const body = document.querySelector('body');
  if (cToggle && body) {
    cToggle.addEventListener('click', () => {
      body.classList.toggle('menu-show');
    });
  }
};

export default toggle;
