import Swiper, { Navigation } from 'swiper';

let projSwiper;
const mqMd = window.matchMedia('screen and (max-width: 1366px)');
// const mqMd = window.matchMedia('screen and (max-width: 767px)');
const mqLg = window.matchMedia('screen and (min-width: 1367x)');
// const mqLg = window.matchMedia('screen and (min-width: 768px)');

function initSlider() {
  if (document.querySelector('.c-projects-search__cat-list')) {
    projSwiper = new Swiper('.c-projects-search__cat-list', {
      slidesPerView: 'auto',
      modules: [Navigation],
      spaceBetween: 10,
      navigation: {
        nextEl: '.project-cat-next',
        prevEl: '.project-cat-prev',
      },
      breakpoints: {
        992: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
      },
    });
  }
}

function destroySlider() {
  if (typeof projSwiper !== 'undefined') {
    projSwiper.destroy(true, true);
  }
}

function screenResize() {
  if (mqLg.matches) {
    destroySlider();
  }
  if (mqMd.matches) {
    initSlider();
  }
}

mqMd.addListener(function mqlf(mql) {
  if (mql.matches) {
    screenResize();
  }
});

mqLg.addListener(function mqlf(mql) {
  if (mql.matches) {
    screenResize();
  }
});

const projectsSearch = () => {
  screenResize();
};

export default projectsSearch;
