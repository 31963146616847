import Swiper from 'swiper';

let searchSwiper;
const mqMd = window.matchMedia('screen and (max-width: 767px)');
const mqLg = window.matchMedia('screen and (min-width: 768px)');

function initSlider() {
  if (document.querySelector('.c-search-bar__radio-wrapper')) {
    searchSwiper = new Swiper('.c-search-bar__radio-wrapper', {
      // slidesPerView: 2.5,
      slidesPerView: 'auto',
      breakpoints: {
        767: {
          slidesPerView: 2,
        },
        1400: {
          slidesPerView: 3,
        },
      },
    });
  }
}

function destroySlider() {
  if (typeof searchSwiper !== 'undefined') {
    searchSwiper.destroy(true, true);
  }
}

function screenResize() {
  if (mqLg.matches) {
    destroySlider();
  }
  if (mqMd.matches) {
    initSlider();
  }
}

mqMd.addListener(function mqlf(mql) {
  if (mql.matches) {
    screenResize();
  }
});

mqLg.addListener(function mqlf(mql) {
  if (mql.matches) {
    screenResize();
  }
});

const searchBar = () => {
  screenResize();
};

export default searchBar;
