import getCookie from '../get-cookie';
import getParameterFromURL from '../get-parameter-from-url';
import addCountriesToMap from './add-countries-to-map';
import controls from './controls';
import countResults from './count-results';
import showMarkers from './show-markers';

const form = (map) => {
  const formHtml = document.querySelector('.c-search-bar__form');
  formHtml.addEventListener('submit', (e) => {
    e.preventDefault();

    const panel = document.querySelector('.panel-content');
    const searchInput = document.querySelector('.c-search-bar__input');
    const loading = document.querySelector('.s-map__loading');

    if (loading) {
      loading.classList.remove('s-map__loading--hidden');
    }

    const formdata = new FormData(formHtml);

    const inputRadioChecked = document.querySelector(
      '.c-form-field__input--radio:checked',
    );

    const ajax = new XMLHttpRequest();

    // eslint-disable-next-line no-undef
    ajax.open('POST', localizeVars.ajax_url, true);
    ajax.onreadystatechange = () => {
      if (ajax.readyState === 4 && ajax.status === 200) {
        if (ajax.responseText) {
          const json = JSON.parse(ajax.responseText);
          if (json.success) {
            if (json.data.searchStatus) {
              panel.innerHTML = json.data.html;
              showMarkers(
                map,
                json.data.isDirect && json.data.isDirect,
                inputRadioChecked.getAttribute('id'),
              );
              controls(true);
              setTimeout(function () {
                map.invalidateSize();
              }, 400);
              searchInput.placeholder = json.data.searchPlaceholder;
              countResults();
              addCountriesToMap(map);

              const relationExists =
                document.querySelectorAll(`.c-single-relation`);

              let userRelation = getParameterFromURL('user-relation');
              // let userRelation = getCookie('user-relation');

              console.log('userRelation: ', userRelation);
              if (userRelation && relationExists.length > 1) {
                userRelation = userRelation.split(',');
                setTimeout(() => {
                  const pickedRelation0 = document.querySelector(
                    `.c-single-relation[post-id="${userRelation[0]}"]:not(.c-single-relation--active)`,
                  );
                  const pickedRelation1 = document.querySelector(
                    `.c-single-relation[post-id="${userRelation[1]}"]:not(.c-single-relation--active)`,
                  );
                  const event = new Event('click');
                  if (pickedRelation0) {
                    pickedRelation0.dispatchEvent(event);
                  } else if (pickedRelation1) {
                    pickedRelation1.dispatchEvent(event);
                  }
                }, 1000);
              }
              if (loading) {
                loading.classList.add('s-map__loading--hidden');
              }
            } else {
              controls(false, false, map);
              if (loading) {
                loading.classList.add('s-map__loading--hidden');
              }
            }
          } else {
            controls(false, false, map);
            if (loading) {
              loading.classList.add('s-map__loading--hidden');
            }
          }
        }
      }
    };

    ajax.send(formdata);
  });
};
export default form;
