const countResults = () => {
  const sourceArr = [];
  const targetArr = [];
  let count = 0;
  let tmpCount = 0;
  const counter = document.querySelectorAll('.counter-number');

  const countIt = document.querySelectorAll(
    '.count-it.c-single-relation--visible',
  );
  const countNot = document.querySelectorAll(
    '.count-not.c-single-relation--visible',
  );

  if (counter.length > 0) {
    if (countIt.length > 0) {
      countIt.forEach((relation) => {
        if (countIt.length === 1) {
          const findSource = relation.querySelectorAll('.relation-source');
          if (findSource.length > 0) {
            count = findSource.length;
          } else {
            count = 1;
          }
        } else {
          // count += 1;

          let getPostIdAttr = '';
          const getRelationSource = relation.querySelector('.relation-source');
          if (getRelationSource) {
            getPostIdAttr = getRelationSource.getAttribute('post-id');
          } else {
            getPostIdAttr = relation.getAttribute('post-id');
          }

          sourceArr.push(getPostIdAttr);
        }
      });
    }

    if (countNot.length > 0) {
      countNot.forEach((relation) => {
        if (countNot.length === 1) {
          const findTarget = relation.querySelectorAll('.relation-target');
          if (findTarget.length > 0) {
            count = findTarget.length;
          } else {
            count = 1;
          }
        } else {
          let getPostIdAttr = '';
          const getRelationTarget = relation.querySelector('.relation-target');
          if (getRelationTarget) {
            getPostIdAttr = getRelationTarget.getAttribute('post-id');
          } else {
            getPostIdAttr = relation.getAttribute('post-id');
          }

          targetArr.push(getPostIdAttr);
        }
      });
    }

    if (targetArr.length > sourceArr.length) {
      tmpCount = targetArr.length;
    } else {
      tmpCount = sourceArr.length;
    }

    if (tmpCount > 0) {
      count = tmpCount;
    }
    counter.forEach((element) => {
      const el = element.querySelector('span');
      el.innerHTML = count;
    });
  }
};
export default countResults;
