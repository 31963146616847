import * as L from 'leaflet';
import setCookie from '../set-cookie';

import countResults from './count-results';
import addParameterToURL from '../add-parameter-to-url';

const showOnlyVisibleOnScreen = (visibleElements) => {
  const sources = document.querySelectorAll('.c-single-relation');
  if (sources) {
    sources.forEach((source) => {
      const postId = source.getAttribute('post-id');
      if (visibleElements.includes(parseInt(postId, 10))) {
        source.classList.add('c-single-relation--visible');
        if (source.parentNode.classList.contains('relation-hover')) {
          source.parentNode.classList.add('c-single-relation--visible');
        }
      } else if (visibleElements.length > 0) {
        source.classList.remove('c-single-relation--visible');
        if (source.parentNode.classList.contains('relation-hover')) {
          source.parentNode.classList.remove('c-single-relation--visible');
        }
      }
    });

    countResults();
  }
};

const handleLeftPanelUpdate = (map) => {
  map.on('moveend', () => {
    const features = [];
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (map.getBounds().contains(layer.getLatLng())) {
          if (typeof layer.options.icon.getAllChildMarkers !== 'undefined') {
            if (
              typeof layer.options.icon.getAllChildMarkers() !== 'undefined'
            ) {
              if (layer.options.icon.getAllChildMarkers().length > 0) {
                const childMarkers = layer.options.icon.getAllChildMarkers();

                childMarkers.forEach((element) => {
                  features.push(element.options.postId);
                });
              }
            }
          } else {
            features.push(layer.options.postId);
          }
        }
      }
    });

    const inputRadioChecked = document.querySelectorAll(
      '.c-form-field__input--radio:checked',
    );

    const userPosition = [
      inputRadioChecked.length > 0
        ? inputRadioChecked[0].getAttribute('data-language')
        : '',
      inputRadioChecked.length > 0
        ? inputRadioChecked[0].getAttribute('data-translation')
        : '',
    ];

    addParameterToURL('user-position', userPosition);
    // setCookie('user-position', userPosition, 7);
    showOnlyVisibleOnScreen(features);
  });
};

export default handleLeftPanelUpdate;
