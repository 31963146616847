import submitFilters from './submit-filters';

const searchByPosition = (lat, lng, direct = null) => {
  const latInput = document.querySelector('.map-lat');
  const lngInput = document.querySelector('.map-lng');
  const directInput = document.querySelector('.map-direct');

  latInput.value = lat;
  lngInput.value = lng;

  // latInput.value = lat + 0.0000001;
  // lngInput.value = lng + 0.0000001;

  if (direct) {
    directInput.value = 'direct';
  }

  submitFilters();
};

export default searchByPosition;
