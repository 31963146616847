import searchByPosition from './search-by-position';

const autocomplete = () => {
  const address = document.getElementById('search');

  if (address) {
    const options = {
      types: [
        'locality',
        'sublocality',
        'country',
        'administrative_area_level_1',
        'administrative_area_level_2',
      ],
      componentRestrictions: { country: ['pl', 'de'] },
    };

    // eslint-disable-next-line no-undef
    const autocompleteInit = new google.maps.places.Autocomplete(
      address,
      options,
    );

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(autocompleteInit, 'place_changed', () => {
      const place = autocompleteInit.getPlace();
      const { location } = place.geometry;
      searchByPosition(location.lat(), location.lng(), true);
    });
  }
};

export default autocomplete;
