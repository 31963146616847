import searchByPosition from './search-by-position';

const setMarkersClick = (markers) => {
  markers.on('click', (e) => {
    const current = e.sourceTarget;
    const position = current.getLatLng();
    const { source } = current.options;

    if (source) {
      searchByPosition(source.lat, source.lng, true);
    } else {
      searchByPosition(position.lat, position.lng, true);
    }
  });
};
export default setMarkersClick;
