import addParameterToURL from './add-parameter-to-url';
import getParameterFromURL from './get-parameter-from-url';
import scrollToSmoothly from './scroll-to-smoothly';

const resetProjects = () => {
  const projects = document.querySelectorAll('.c-projects-search__cat-link');
  if (projects) {
    projects.forEach((element) => {
      element.classList.remove('c-projects-search__cat-link--active');
    });
  }
};

const jugendProjekte = () => {
  let offset = 0;
  const searchBar = document.querySelector('.c-projects-search');
  const header = document.querySelector('.c-header');
  if (header) {
    offset += header.clientHeight;
  }
  if (searchBar) {
    offset += searchBar.clientHeight;
  }
  const body = document.querySelector('body');
  const projects = document.querySelectorAll('.c-projects-search__cat-link');

  if (projects.length > 0) {
    projects.forEach((element) => {
      element.addEventListener('click', (e) => {
        resetProjects();
        e.currentTarget.classList.add('c-projects-search__cat-link--active');
        const search = document.getElementById(
          e.currentTarget.getAttribute('data-target'),
        );
        if (search) {
          scrollToSmoothly(search.offsetTop - offset, 1000);
        }
        addParameterToURL(
          'project-data-translation',
          e.currentTarget.getAttribute('data-translation'),
        );

        addParameterToURL(
          'project-data-target',
          e.currentTarget.getAttribute('data-target'),
        );
      });
    });
  }

  if (!body.classList.contains('single')) {
    if (
      getParameterFromURL('project-data-translation') &&
      !getParameterFromURL('tab-data-translation')
    ) {
      let searchBtn = '';
      searchBtn = document.querySelector(
        `[data-target="${getParameterFromURL('project-data-translation')}"]`,
      );
      if (!searchBtn) {
        searchBtn = document.querySelector(
          `[data-target="${getParameterFromURL('project-data-target')}"]`,
        );
      }
      if (searchBtn) {
        searchBtn.classList.add('c-projects-search__cat-link--active');
        const getHtref = searchBtn.getAttribute('href').split('#')[1];
        if (getHtref) {
          const searchScrollTarget = document.getElementById(getHtref);
          if (searchScrollTarget) {
            setTimeout(() => {
              scrollToSmoothly(searchScrollTarget.offsetTop - offset, 1000);
            }, 1000);
          }
        }
      }
    } else if (getParameterFromURL('tab-data-translation')) {
      const searchBtn = document.querySelector(
        `[data-slug="${window.location.hash.split('#')[1]}"]`,
      );
      if (searchBtn) {
        const findLink = searchBtn.querySelector(
          '.c-projects-search__cat-link',
        );
        if (findLink) {
          findLink.classList.add('c-projects-search__cat-link--active');
          const getHtref = searchBtn.getAttribute('href');
          if (getHtref) {
            const searchScrollTarget = document.getElementById(getHtref);
            if (searchScrollTarget) {
              setTimeout(() => {
                scrollToSmoothly(searchScrollTarget.offsetTop - offset, 1000);
              }, 1000);
            }
          }
        }
      }
    }

    if (getParameterFromURL('project-data-target')) {
      const searchBtn = document.querySelector(
        `[data-target="${getParameterFromURL('project-data-target')}"]`,
      );
      if (searchBtn) {
        searchBtn.classList.add('c-projects-search__cat-link--active');
      }
    }
  }
};

export default jugendProjekte;
