import drawBorders from './draw-borders';
import drawLines from './draw-lines';
import setMarkers from './set-markers';

const showMarkers = (map, direct = null, formType = null) => {
  let drawLinesStatus = false;
  const sources = document.querySelectorAll('.relation-source');
  const partners = [];
  if (sources) {
    sources.forEach((el) => {
      el.classList.remove('c-single-relation--active');
      const current = el;

      const postId = current.getAttribute('post-id');
      const sourcePlaceId = current.getAttribute('place-id');
      const sourceAddress = current.getAttribute('address');
      const sourceLat = current.getAttribute('data-lat');
      const sourceLng = current.getAttribute('data-lng');

      partners.push({
        lat: parseFloat(sourceLat),
        lng: parseFloat(sourceLng),
        postId: parseInt(postId, 10),
        placeId: sourcePlaceId,
        address: sourceAddress,
      });

      const targets = current.querySelectorAll('.relation-target');

      if (targets) {
        targets.forEach((target) => {
          const targetPostId = target.getAttribute('post-id');
          const targetLat = target.getAttribute('data-lat');
          const targetLng = target.getAttribute('data-lng');
          const targetPlaceId = target.getAttribute('place-id');
          const targetAddress = target.getAttribute('address');

          partners.push({
            lat: parseFloat(targetLat),
            lng: parseFloat(targetLng),
            postId: parseInt(postId, 10),
            placeId: sourcePlaceId,
            address: sourceAddress,
            source: {
              postId: parseInt(targetPostId, 10),
              lat: parseFloat(sourceLat),
              lng: parseFloat(sourceLng),
              placeId: targetPlaceId,
              address: targetAddress,
            },
          });
        });
      }
    });
    setMarkers(partners, map, direct);

    if (direct && sources) {
      sources.forEach((element) => {
        const links = element.querySelectorAll('.c-single-relation__links');
        element.classList.add('c-single-relation--active');
        drawLinesStatus = true;
        if (links.length > 0) {
          links.forEach((link) => {
            link.classList.add('c-single-relation__links--show');
          });
        }
      });
    }

    if (drawLinesStatus) {
      drawLines(partners, map);
      drawBorders(partners, map, formType);
    }
  }
};
export default showMarkers;
