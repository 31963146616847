import * as L from 'leaflet';
import 'leaflet-curve';

const drawLines = (coords, map) => {
  coords.forEach((element) => {
    const latlngs = [];

    const latlng1 = [coords[0].lat, coords[0].lng];
    const latlng2 = [element.lat, element.lng];

    const offsetX = latlng2[1] - latlng1[1];
    const offsetY = latlng2[0] - latlng1[0];

    const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
    const theta = Math.atan2(offsetY, offsetX);

    const thetaOffset = 3.14 / 10;

    const r2 = r / 2 / Math.cos(thetaOffset);
    const theta2 = theta + thetaOffset;

    const midpointX = r2 * Math.cos(theta2) + latlng1[1];
    const midpointY = r2 * Math.sin(theta2) + latlng1[0];

    const midpointLatLng = [midpointY, midpointX];

    latlngs.push(latlng1, midpointLatLng, latlng2);

    const pathOptions = {
      color: '#29449C',
      weight: 2,
      dashArray: '10px',
      className: 'asdasdasdas',

      // stroke?: boolean | undefined;
      // color?: string | undefined;
      // weight?: number | undefined;
      // opacity?: number | undefined;
      // lineCap?: LineCapShape | undefined;
      // lineJoin?: LineJoinShape | undefined;
      // dashArray?: string | number[] | undefined;
      // dashOffset?: string | undefined;
      // fill?: boolean | undefined;
      // fillColor?: string | undefined;
      // fillOpacity?: number | undefined;
      // fillRule?: FillRule | undefined;
      // renderer?: Renderer | undefined;
      // className?: string | undefined;
    };

    const curvedPath = new L.Curve(
      ['M', latlng1, 'Q', midpointLatLng, latlng2],
      pathOptions,
    ).addTo(map);
  });
};

export default drawLines;
