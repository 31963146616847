import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-boundary-canvas';
import addCountriesToMap from './add-countries-to-map';

const initMap = () => {
  const latlng = new L.LatLng(51.960521, 14.728861);

  const map = new L.Map('osm-map', {
    center: latlng,
    zoom: 5,
  });

  const osm = L.TileLayer.boundaryCanvas(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, UK shape <a href="https://github.com/johan/world.geo.json">johan/word.geo.json</a>',
    },
  );

  addCountriesToMap(map);

  map.addLayer(osm);

  // eslint-disable-next-line no-underscore-dangle
  const originalInitTile = L.GridLayer.prototype._initTile;
  L.GridLayer.include({
    // eslint-disable-next-line no-underscore-dangle
    _initTile(tile) {
      originalInitTile.call(this, tile);

      const tileSize = this.getTileSize();

      // eslint-disable-next-line no-param-reassign
      tile.style.width = `${tileSize.x + 1}px`;
      // eslint-disable-next-line no-param-reassign
      tile.style.height = `${tileSize.y + 1}px`;
    },
  });

  return map;
};
export default initMap;
