import * as L from 'leaflet';

const addCountriesToMap = (map) => {
  // https://geojson-maps.ash.ms
  // eslint-disable-next-line no-undef
  fetch(localizeVars.settings.coordinatesFile)
    .then((res) => res.json())
    .then((data) => {
      const countries = {
        type: 'FeatureCollection',
        features: [],
      };

      data.features.forEach((element) => {
        countries.features.push(element);
      });

      L.geoJSON(countries, {
        style() {
          return {
            fillColor: '#29449c',
            fillOpacity: 0.15,
            stroke: true,
            color: '#29449c',
            weight: 0.1,
          };
        },
      })
        // .bindPopup((layer) => layer.feature.properties.name)
        .addTo(map);
    });
};
export default addCountriesToMap;
