const deleteParameterFromURL = (name) => {
  // Get the current URL
  const url = new URL(window.location.href);

  // Remove the parameter
  url.searchParams.delete(name);

  // Update the browser's address bar
  window.history.replaceState({}, document.title, url.href);

  const wpmlLs = document.querySelector('.wpml-ls');

  if (wpmlLs) {
    const getLinks = wpmlLs.querySelectorAll('a');

    if (getLinks.length > 0) {
      getLinks.forEach((single) => {
        const href = new URL(single.getAttribute('href'));

        href.searchParams.delete(name);

        single.setAttribute('href', href);
      });
    }
  }
};

export default deleteParameterFromURL;
