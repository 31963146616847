import addCountriesToMap from './add-countries-to-map';

const controls = (show = true, searchResults = true, map = null) => {
  const sMapContainer = document.querySelector('.s-map__container');
  const searchResultsFailed = document.querySelector(
    '.s-map__search-results-failed',
  );

  const description = document.querySelector('.s-map__description');
  const cSearchBarFieldSearch = document.querySelector(
    '.c-search-bar__field--search',
  );

  if (sMapContainer) {
    if (searchResultsFailed) {
      searchResultsFailed.classList.remove(
        's-map__search-results-failed--show',
      );
    }
    if (description) {
      description.classList.remove('show');
    }
    if (
      sMapContainer.classList.contains('s-map__container--hide-panel') &&
      show
    ) {
      sMapContainer.classList.remove('s-map__container--hide-panel');
      if (cSearchBarFieldSearch) {
        cSearchBarFieldSearch.classList.add('visible');
      }
    } else if (
      !sMapContainer.classList.contains('s-map__container--hide-panel') &&
      !show
    ) {
      if (!searchResults) {
        map.eachLayer(function (layer) {
          if (layer instanceof L.Curve) {
            map.removeLayer(layer);
          }
        });

        map.eachLayer(function (layer) {
          if (layer instanceof L.MarkerClusterGroup) {
            map.removeLayer(layer);
          }
        });

        map.eachLayer(function (layer) {
          if (layer instanceof L.Polygon) {
            map.removeLayer(layer);
          }
        });

        addCountriesToMap(map);

        setTimeout(function () {
          map.invalidateSize();
        }, 400);

        sMapContainer.classList.add('s-map__container--hide-panel');
        if (cSearchBarFieldSearch) {
          cSearchBarFieldSearch.classList.remove('visible');
        }
        if (searchResultsFailed) {
          searchResultsFailed.classList.add(
            's-map__search-results-failed--show',
          );
          const radiosFilters = document.querySelectorAll(
            '.c-form-field__input--radio',
          );
          if (radiosFilters) {
            radiosFilters.forEach((radio) => {
              // eslint-disable-next-line no-param-reassign
              radio.checked = false;
            });
          }
        }
        // searchResultsFailed.classList.add('s-map__search-results-failed--show');
        // description.classList.add('show');
      }
    }
  }
};
export default controls;
