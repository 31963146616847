import SimpleLightbox from 'simplelightbox';

import Swiper, { Navigation, Pagination } from 'swiper';

// function print(e) {
//   e.preventDefault();
//   const printwin = window.open('');
//   const desc = document.querySelector('#project-desc').innerHTML;
//   const image = document.querySelector('#project-image').innerHTML;
//   const imageBackup = document.querySelector('#project-image-backup').innerHTML;
//   printwin.document.write(desc + image + imageBackup);
//   printwin.print();
// }

const singleProject = () => {
  const lightbox = new SimpleLightbox('.gallery a', {
    /* options */
  });

  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.c-single-project__gallery-slider', {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    watchSlidesProgress: true,
    loop: true,
    navigation: {
      nextEl: '.gallery-next',
      prevEl: '.gallery-prev',
    },
  });

  // const prints = document.querySelectorAll(
  //   '.c-single-project__attachment-print',
  // );
  // if (prints !== null) {
  //   prints.forEach((el) => {
  //     el.addEventListener('click', print);
  //   });
  // }
};

export default singleProject;
