import controls from './controls';
import fistInputCheck from './first-input-check';
import addCountriesToMap from './add-countries-to-map';
import getCookie from '../get-cookie';
import getParameterFromURL from '../get-parameter-from-url';

const handleMapInteraction = (map) => {
  const container = document.querySelector('.s-map__container');

  // uruchomienie kontrolek w momencie kliknięcia w mapę
  map.addEventListener('click', () => {
    if (container.classList.contains('s-map__container--hide-panel')) {
      controls(true);
      fistInputCheck();
      addCountriesToMap(map);
    }
  });

  let userRelation = getParameterFromURL('user-relation');
  // let userRelation = getCookie('user-relation');

  let userPosition = getParameterFromURL('user-position');
  console.log('userPosition: ', userPosition);
  // let userPosition = getCookie('user-position');

  if (userPosition && !userRelation) {
    setTimeout(() => {
      controls(true);
      userPosition = userPosition.split(',');
      fistInputCheck(false, userPosition[0]);
      addCountriesToMap(map);
    }, 1000);
  } else if (userRelation) {
    setTimeout(() => {
      controls(true);
      userRelation = userRelation.split(',');
      fistInputCheck(false, userRelation[2]);
      addCountriesToMap(map);
    }, 1000);
  }
};
export default handleMapInteraction;
