const scrollToSmoothly = (pos, time) => {
  const currentPos = window.pageYOffset;
  let start = null;
  let posP = pos;
  let timeP = time;
  if (timeP == null) timeP = 500;
  posP = +posP;
  timeP = +timeP;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    const progress = currentTime - start;
    if (currentPos < posP) {
      window.scrollTo(0, ((posP - currentPos) * progress) / timeP + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - posP) * progress) / timeP);
    }
    if (progress < timeP) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, posP);
    }
  });
};

export default scrollToSmoothly;
