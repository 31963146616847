import Swiper from 'swiper';

let instiSwiper;
const mqMd = window.matchMedia('screen and (max-width: 767px)');
const mqLg = window.matchMedia('screen and (min-width: 768px)');

function initSlider() {
  if (document.querySelector('.c-institutions-search__cat-list')) {
    instiSwiper = new Swiper('.c-institutions-search__cat-list', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      breakpoints: {
        767: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1400: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });
  }
}

function destroySlider() {
  if (typeof instiSwiper !== 'undefined') {
    instiSwiper.destroy(true, true);
  }
}

function screenResize() {
  if (mqLg.matches) {
    destroySlider();
  }
  if (mqMd.matches) {
    initSlider();
  }
}

mqMd.addListener(function mqlf(mql) {
  if (mql.matches) {
    screenResize();
  }
});
mqLg.addListener(function mqlf(mql) {
  if (mql.matches) {
    screenResize();
  }
});

const institutionsSearch = () => {
  screenResize();
};

export default institutionsSearch;
