import initMap from './init-map';
import setMarkers from './set-markers';

const showRelation = () => {
  const sources = document.querySelectorAll('.relation-source');
  if (sources) {
    sources.forEach((el) => {
      el.addEventListener('click', (e) => {
        const mapInit = initMap();

        const partners = [];
        const current = e.currentTarget;

        const sourceLat = current.getAttribute('data-lat');
        const sourceLng = current.getAttribute('data-lng');

        partners.push({
          lat: parseFloat(sourceLat),
          lng: parseFloat(sourceLng),
        });

        const targets = current.querySelectorAll('.relation-target');

        if (targets) {
          targets.forEach((target) => {
            const targetLat = target.getAttribute('data-lat');
            const targetLng = target.getAttribute('data-lng');

            partners.push({
              lat: parseFloat(targetLat),
              lng: parseFloat(targetLng),
            });
          });
        }

        setMarkers(partners, mapInit);
      });
    });
  }
};
export default showRelation;
