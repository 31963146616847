const fistInputCheck = (current = false, choosen = null) => {
  if (current) {
    const radiosChecked = document.querySelector(
      '.c-form-field__input--radio:checked',
    );
    if (radiosChecked) {
      radiosChecked.checked = true;
      const event = new Event('change');
      radiosChecked.dispatchEvent(event);
    }
  } else if (choosen) {
    const choosenChecked = document.querySelector(
      `[data-language="${choosen}"]`,
    );
    if (choosenChecked) {
      choosenChecked.checked = true;
      const event = new Event('change');
      choosenChecked.dispatchEvent(event);
    } else {
      const choosenInsteadChecked = document.querySelector(
        `[data-translation="${choosen}"]`,
      );
      choosenInsteadChecked.checked = true;
      const event = new Event('change');
      choosenInsteadChecked.dispatchEvent(event);
    }
  } else {
    const radios = document.querySelectorAll('.c-form-field__input--radio');
    radios[0].checked = true;
    const event = new Event('change');
    radios[0].dispatchEvent(event);
  }
};

export default fistInputCheck;
