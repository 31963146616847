import deleteParameterFromURL from './delete-parameter-from-url';
import autocomplete from './map/autocomplete';
import controls from './map/controls';
import countResults from './map/count-results';
import fistInputCheck from './map/first-input-check';
import form from './map/form';
import handleMapInteraction from './map/handle-map-interaction';
import handleRadioChange from './map/handle-radio-change';
import initMap from './map/init-map';
import showRelation from './map/show-relation';
import setCookie from './set-cookie';

const map = () => {
  // jeśli DIV z mapą istnieje to wyświetlenie jej
  const mapInit = initMap();

  if (mapInit) {
    // zainicjowanie autocomplete
    autocomplete();

    handleMapInteraction(mapInit);

    showRelation();
    form(mapInit);
    handleRadioChange();
  }

  const closeError = document.querySelector(
    '.s-map__search-results-failed-box-close',
  );
  if (closeError) {
    closeError.addEventListener('click', (e) => {
      e.currentTarget
        .closest('.s-map__search-results-failed')
        .classList.remove('s-map__search-results-failed--show');
      fistInputCheck(true);
    });
  }

  const sMapReset = document.querySelector('.s-map__reset');
  if (sMapReset) {
    sMapReset.addEventListener('click', (e) => {
      e.preventDefault();
      fistInputCheck(true);
      deleteParameterFromURL('user-relation');
      deleteParameterFromURL('user-position');
      // setCookie('user-relation', ' ');
    });
  }
};

export default map;
