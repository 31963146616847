import searchByPosition from './search-by-position';

const setPanelClick = () => {
  const sources = document.querySelectorAll('.c-single-relation');
  if (sources) {
    sources.forEach((source) => {
      const lat = source.getAttribute('data-lat');
      const lng = source.getAttribute('data-lng');
      source.addEventListener('click', () => {
        searchByPosition(parseFloat(lat), parseFloat(lng), true);
      });
    });
  }
};
export default setPanelClick;
