import * as L from 'leaflet';
import searchLocalization from './search-localiation';

const drawBorders = (partners, map, formType) => {
  map.eachLayer((layer) => {
    if (layer instanceof L.Polygon) {
      map.removeLayer(layer);
    }
  });

  const administrativeAreaLevelArr = [];
  partners.forEach((el) => {
    if (!administrativeAreaLevelArr.includes(el.placeId)) {
      administrativeAreaLevelArr.push(el.placeId);

      searchLocalization(el.address, formType, map);
    }
    if (el.source) {
      if (!administrativeAreaLevelArr.includes(el.source.placeId)) {
        administrativeAreaLevelArr.push(el.source.placeId);
        searchLocalization(el.source.address, formType, map);
      }
    }
  });
  // ----------------------------------------

  // const administrativeAreaLevel2Arr = [];

  // partners.forEach((el) => {
  //   if (!administrativeAreaLevel2Arr.includes(el.placeId)) {
  //     administrativeAreaLevel2Arr.push(el.placeId);
  //     searchLocalization(el.address, 'county', map);
  //   }
  //   if (el.source) {
  //     if (!administrativeAreaLevel2Arr.includes(el.source.placeId)) {
  //       searchLocalization(el.source.address, 'county', map);
  //     }
  //   }
  // });
};
export default drawBorders;
