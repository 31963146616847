import addParameterToURL from '../add-parameter-to-url';
import getCookie from '../get-cookie';
import getParameterFromURL from '../get-parameter-from-url';
import setCookie from '../set-cookie';
import submitFilters from './submit-filters';

const handleRadioChange = () => {
  const form = document.querySelector('.c-search-bar__form');
  const address = document.getElementById('search');
  const lat = document.querySelector('.map-lat');
  const lng = document.querySelector('.map-lng');
  const direct = document.querySelector('.map-direct');
  const formRadio = form.querySelectorAll('.c-form-field__input--radio');

  formRadio.forEach((el) => {
    el.addEventListener('change', (e) => {
      e.preventDefault();
      address.value = '';
      lat.value = '';
      lng.value = '';
      direct.value = '';

      let userRelation = getParameterFromURL('user-relation');
      // let userRelation = getCookie('user-relation');
      if (userRelation) {
        const dataLanguage = e.currentTarget.getAttribute('data-language');
        const dataTranslation =
          e.currentTarget.getAttribute('data-translation');
        userRelation = userRelation.split(',');
        if (
          !userRelation.includes(dataLanguage) &&
          !userRelation.includes(dataTranslation)
        ) {
          addParameterToURL('user-relation', '');
          // setCookie('user-relation', ' ');
        }
      }

      let userPosition = getParameterFromURL('user-position');
      // let userPosition = getCookie('user-position');

      if (userPosition) {
        const dataLanguage = e.currentTarget.getAttribute('data-language');
        const dataTranslation =
          e.currentTarget.getAttribute('data-translation');
        userPosition = userPosition.split(',');
        if (
          !userPosition.includes(dataLanguage) &&
          !userPosition.includes(dataTranslation)
        ) {
          addParameterToURL('user-position', '');
          // setCookie('user-position', ' ');
        }
      }

      submitFilters();
    });
  });
};

export default handleRadioChange;
