import * as L from 'leaflet';
import setCookie from '../set-cookie';
import handleLeftPanelUpdate from './handle-left-panel-update';
import setMarkersClick from './set-markers-click';
import setPanelClick from './set-panel-click';
import addParameterToURL from '../add-parameter-to-url';

const setMarkers = (coords, map, direct = null) => {
  const markers = new L.MarkerClusterGroup({
    iconCreateFunction(cluster) {
      const markersInCluster = cluster.getAllChildMarkers();
      // eslint-disable-next-line no-plusplus
      return L.divIcon({
        html: `<span>${markersInCluster.length}</span>`,
        className: 'cluster-map',
        iconSize: L.point(60, 60),
      });
    },
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
  });

  map.eachLayer(function (layer) {
    if (layer instanceof L.Curve) {
      map.removeLayer(layer);
    }
  });

  map.eachLayer(function (layer) {
    if (layer instanceof L.MarkerClusterGroup) {
      map.removeLayer(layer);
    }
  });

  map.eachLayer(function (layer) {
    if (layer instanceof L.Polygon) {
      map.removeLayer(layer);
    }
  });

  const markersList = [];
  const sources = document.querySelectorAll('.relation-source');
  const sMapReset = document.querySelector('.s-map__reset');
  if (direct && direct === 'direct') {
    // eslint-disable-next-line no-param-reassign
    const added = [];
    coords.forEach((localization, index) => {
      if (localization.lat && localization.lng) {
        let icon = '';
        // eslint-disable-next-line no-undef
        if (index === 0 && localizeVars.language === 'pl') {
          // eslint-disable-next-line no-undef
          icon = localizeVars.settings.markerPrimary;
          // eslint-disable-next-line no-undef
        } else if (index > 0 && localizeVars.language === 'pl') {
          // eslint-disable-next-line no-undef
          icon = localizeVars.settings.markerSecondary;
        }

        // eslint-disable-next-line no-undef
        if (index === 0 && localizeVars.language === 'de') {
          // eslint-disable-next-line no-undef
          icon = localizeVars.settings.markerPrimary;
          // eslint-disable-next-line no-undef
        } else if (index > 0 && localizeVars.language === 'de') {
          // eslint-disable-next-line no-undef
          icon = localizeVars.settings.markerSecondary;
        }

        const markerIcon = L.icon({
          iconUrl: icon,
          iconSize: [30, 40], // size of the icon
          iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
        });

        const marker = new L.LatLng(localization.lat, localization.lng);
        const m = new L.Marker(marker, {
          source:
            localization.source &&
            localization.source.lat &&
            localization.source.lng
              ? // eslint-disable-next-line no-undef
                new L.LatLng(localization.source.lat, localization.source.lng)
              : false,
          postId: parseInt(localization.postId, 10),
          icon: markerIcon,
        });

        if (!added.includes(localization.lat)) {
          markersList.push(m);
          markers.addLayer(m);
          added.push(localization.lat);
        }
      }
    });
    sMapReset.classList.remove('s-map__reset--hidden');

    const inputRadioChecked = document.querySelectorAll(
      '.c-form-field__input--radio:checked',
    );
    const userRelation = [
      sources[0].getAttribute('post-id'),
      sources[0].getAttribute('post-translation'),
      inputRadioChecked.length > 0
        ? inputRadioChecked[0].getAttribute('data-translation')
        : '',
      inputRadioChecked.length > 0
        ? inputRadioChecked[0].getAttribute('data-language')
        : '',
    ];
    addParameterToURL('user-relation', userRelation);
    // setCookie('user-relation', userRelation, 7);
  } else {
    // eslint-disable-next-line no-param-reassign
    const added = [];
    coords.forEach((localization) => {
      if (localization.lat && localization.lng) {
        const markerIcon = L.icon({
          iconUrl:
            // eslint-disable-next-line no-undef
            localizeVars.settings.markerSecondary,
          iconSize: [30, 40], // size of the icon
          iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
        });

        const marker = new L.LatLng(localization.lat, localization.lng);
        const m = new L.Marker(marker, {
          icon: markerIcon,
          source:
            localization.source &&
            localization.source.lat &&
            localization.source.lng
              ? // eslint-disable-next-line no-undef
                { lat: localization.source.lat, lng: localization.source.lng }
              : false,
          postId: parseInt(localization.postId, 10),
        });

        if (!added.includes(localization.postId)) {
          markersList.push(m);
          markers.addLayer(m);
          setMarkersClick(m);
          added.push(localization.postId);
        }
      }
      sMapReset.classList.add('s-map__reset--hidden');
    });
  }
  setPanelClick();

  map.addLayer(markers);

  handleLeftPanelUpdate(map, markersList);

  if (window.matchMedia('screen and (max-width: 992px)').matches) {
    map.fitBounds(markers.getBounds());
  } else {
    map.fitBounds(markers.getBounds(), { padding: [200, 100] });
  }
};
export default setMarkers;
